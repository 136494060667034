import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container } from "../components/layoutComponents";
import Layout from "../components/layout";
import SEO from "../components/seo";

const SeoData = {
  title: "Tabletop Streamer",
  description: "Streaming software for tabletop wargames",
  keywords: [`Tabletop Streamer`, `application`, `react`],
  image: "/seo-preview.jpeg",
  url: "https://www.bignetwork.com",
  titleTemplate: "Tabletop Streamer",
};

const IndexPage = () => (
  <Layout>
    <StyledContainer>
      <SEO
        title={SeoData.title}
        keywords={SeoData.keywords}
        description={SeoData.description}
        url={SeoData.url}
        titleTemplate={SeoData.titleTemplate}
      />
      <WrapperMessage>
        <h1>Thank you and keep in touch</h1>
        <BackTo to="/">
          <Arrow /> <span>back to main</span>
        </BackTo>
      </WrapperMessage>
    </StyledContainer>
  </Layout>
);

const WrapperMessage = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 0 32px;
  margin: 0 auto;
  font-family: poppins;
  text-align: center;

  h1 {
    line-height: 1.2;
  }
`;

const StyledContainer = styled(Container)`
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const BackTo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #80ed99;
`;

const Arrow = styled.div`
  background: #80ed99;
  height: 3px;
  width: 30px;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
  transform: rotate(-180deg);
  transition: all 0.3s ease-in-out;
  right: 0;

  ${BackTo}:hover & {
    right: 12px;
  }

  &:before,
  &:after {
    content: "";
    background: #80ed99;
    position: absolute;
    height: 3px;
    width: 15px;
  }

  &:before {
    right: -3px;
    bottom: -4px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -3px;
    top: -4px;
    transform: rotate(45deg);
  }
`;

export default IndexPage;
